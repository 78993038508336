import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, Select, TextField, TextareaAutosize, Theme, ThemeProvider, Tooltip, Typography, createTheme, styled, withStyles } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from "react";
import { Overrides } from "@material-ui/core/styles/overrides";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import CustomTextField from "../../../components/src/CoustomTextfield.web";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CustomeButton from "../../../components/src/CustomeButton.web";


export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '15px',
    maxWidth: 'none',
    height: '30px',
    position: 'relative',
    bottom: '8px'
  },
  arrow: {
    fontSize: 15,
    left: '0' as any,
    margin: '-4% 0% 0% -1%',
    color: "white",
    "&::before": {
      backgroundColor: "white",
    }
  }
}))(Tooltip);

const ButtonCloseRestore = {
  height: '56px', width: '180px', padding: '4px 8px', justifyContent: 'center',
  alignItems: 'center', flexShrink: 0, textAlign: 'center' as 'center', fontFamily: 'Inter, sans-serif',
  fontSize: '16px', fontWeight: 600, letterSpacing: '-0.408px', borderRadius: '70px', color: '#fff',
  textTransform: 'capitalize' as 'capitalize', border: '1px solid #fff',
};
const ErrorTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '10px',
    maxWidth: 'none',
    left: "40%"
  },
  arrow: {
    fontSize: 20,
    left: '12px !important',
    marginLeft: "0px  !important",
    color: "white",

    "&::before": {
      backgroundColor: "white",
    }
  }
}))(Tooltip);

const TextLimitBox = styled(Box)({
  color: 'white',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
  bottom: '35px',
  right: '25px',
  "@media(max-width: 500px)": {
    bottom: '20px',
  },
  "@media(max-width: 400px)": {
    bottom: '13px',
  }
});

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openSubscriptionDetail: boolean;
  rawInput: string,
  logInToken: any;
  AllSubscritionData: any;
  subscriptionProfileData: any;
  branchName: string;
  isSubscriptionArchived: boolean;
  openArchiveSubscriptionDialog: boolean;
  getActiveData: any
  isActive: string
  selectedSubscription: any
  getArchivedData: any
  selectOption: any
  selectedIndex: number
  isCreate: boolean
  subscriptionName: any,
  selectBranch: any,
  selectPrice: any,
  customPrice: any,
  isOtherSelected: boolean,
  customPriceError: any,
  selectPer: any,
  selectDuration: any,
  selectNumberUnits: any,
  selectPauses: any,
  description: any,
  unitLabel: any,
  errorText: string,
  isError: boolean,
  branch_id: number,
  allBranches: any,
  businessData: any,
  business_id: string,
  loading: boolean,
  selectedOption: any,
  descriptionLength: any,
  selectedBranches: any,
  isEdit: boolean,
  brachesNames: any,
  mode: any,
  restoreSubscriptionDialog: boolean,
  restoreSubscriptionDialogData: any,
  isRestored: boolean,
  selectAll: any,
  branchSelectedIds: any,
  errors: { [key: string]: string }
  joinedId: any

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customform4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSubscriptionListApiId: string = "";
  removeArchiveSubscriptionApiId: string = "";
  archiveSubscriptionApiId: string = "";
  activeSubscriptionApiId: string = "";
  archivedSubscriptionApiId: string = "";
  createSubscriptionApiId: string = "";
  editSubscriptionApiId: string = "";
  getAllBusinessDataApiId: string = "";
  getSelectBranchApiId: string = "";
  viewSubscriptionApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openSubscriptionDetail: false,
      rawInput: "",
      logInToken: null,
      AllSubscritionData: null,
      subscriptionProfileData: null,
      branchName: "aky1",
      isSubscriptionArchived: false,
      openArchiveSubscriptionDialog: false,
      getActiveData: null,
      isActive: "active",
      selectedSubscription: null,
      getArchivedData: null,
      selectOption: null,
      selectedIndex: 0,
      isCreate: false,
      subscriptionName: "",
      selectBranch: 'Select branch',
      selectPrice: '',
      customPrice: "",
      isOtherSelected: false,
      customPriceError: '',
      selectDuration: 'Select duration',
      selectNumberUnits: 'Select number of units',
      selectPer: 'Select frequency',
      selectPauses: 'Select number',
      description: "",
      unitLabel: "",
      errorText: "",
      isError: false,
      branch_id: 0,
      allBranches: [],
      businessData: null,
      business_id: "",
      loading: false,
      selectedOption: "active",
      descriptionLength: 0,
      selectedBranches: [],
      brachesNames: null,
      isEdit: false,
      mode: '',
      restoreSubscriptionDialog: false,
      restoreSubscriptionDialogData: null,
      isRestored: false,
      selectAll: [''],
      branchSelectedIds: [],
      errors: {},
      joinedId: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleChangeBranches = this.handleChangeBranches.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ loading: false });
      }
      switch (apiRequestCallId) {
        case this.removeArchiveSubscriptionApiId:
          this.handleRemoveArchiveSubscriptionApiRes(responseJson);
          break;
        case this.archiveSubscriptionApiId:
          this.handleArchiveSubscriptionApiRes(responseJson);
          break;
        case this.activeSubscriptionApiId:
          this.handleAllActiveSubscriptionListApiRes(responseJson);
          break;
        case this.archivedSubscriptionApiId:
          this.handleAllArchivedSubscriptionListApiRes(responseJson);
          break;
        case this.getAllBusinessDataApiId:
          this.getAllBusinessApiRes(responseJson);
          break;
        case this.getSelectBranchApiId:
          this.handleSelectBranchApiRes(responseJson);
          break;
        case this.editSubscriptionApiId:
          this.editApiRes(responseJson);
          break;
        case this.viewSubscriptionApiId:
          this.viewDetailsApiRes(responseJson)
          break;
        case this.createSubscriptionApiId:
          this.createApiRes(responseJson);
          break;
        default:
          break;
      }

      runEngine.debugLog("Message Recived", message);
    }

    // Customizable Area End
  }

  txtInputWebProps =
    {
      onChangeText: (text: string) => {
        this.setState({ txtInputValue: text });
      },
      secureTextEntry:
        false,
    };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    keyboardType: "email-address",
    autoCompleteType: "email"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
      this.txtInputProps.secureTextEntry = !this.state.enableField;
    },
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // web events
  setInputValue = (text: string) => {
    this.setState(
      { txtInputValue: text }
    );
  };

  setEnableField = () => {
    this.setState(
      { enableField: !this.state.enableField }
    );
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const logInRes = await getStorageData('logInRes', true);
    const otpConfRes = await getStorageData('otpConfRes', true);

    this.setTokenInStorage(token, logInRes, otpConfRes);
    const storedIdsArrayJson = localStorage.getItem('branchIds');
    const storedIdsArray = storedIdsArrayJson ? JSON.parse(storedIdsArrayJson) : [];

    this.setState({ joinedId: storedIdsArray })

    this.fetchAllBusinessListData()
    this.handleActiveSubscriptionApi()
    this.handleArchivedSubscriptionApi()

  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.isSubscriptionArchived !== this.state.isSubscriptionArchived) {
      this.handleArchivedSubscriptionApi()
      this.handleActiveSubscriptionApi()
    }
  }

  handleBackToSubscriptionList = () => {
    this.setState({ openSubscriptionDetail: false, isEdit: false, mode: "" });
  }

  fetchAllBusinessListData = async () => {
    this.setState({ isActive: 'active' })
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllBusinessDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessUserDataApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubscriptionRestoreApi = (id: number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.removeArchiveSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeArchiveSubscriptionApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleArchivedSubscriptionApi()
  }

  handleSubscriptionArchiveApi = (id: number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.archiveSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.archiveSubscriptionApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleRemoveArchiveSubscriptionApiRes = (response: any) => {
    if (!response.errors) {
      this.setState({ isSubscriptionArchived: false, isRestored: true, restoreSubscriptionDialog: false });
    }
    this.handleArchivedSubscriptionApi()
  }

  handleArchiveSubscriptionApiRes = (response: any) => {
    if (!response.errors) {
      this.setState({ isSubscriptionArchived: true, openArchiveSubscriptionDialog: false });
    }
  }

  handleViewDetailBtn = (listItem: any) => {
    let data = {};
    this.setState({ errors: {} });
    const { id, attributes } = listItem;
    data = { id, ...attributes };
    this.viewSubscriptionAPI(id)
  }


  handleSubscriptionRestoreBtn = () => {
    this.setState({ loading: true })
    if (this.state.subscriptionProfileData) {
      const { id } = this.state.subscriptionProfileData;
      this.handleSubscriptionRestoreApi(id);
    }
  }

  handleSubscriptionArchiveBtn = () => {
    this.setState({ openArchiveSubscriptionDialog: true });
  }

  handleArchiveDialogCloseBtn = () => {
    this.setState({ openArchiveSubscriptionDialog: false });
  }

  handleRestoreSubscriptionOpen = () => {
    this.setState({ restoreSubscriptionDialog: true });
  }

  handleRestoreDialogClose = () => {
    this.setState({ restoreSubscriptionDialog: false });
  }

  handleArchiveDialogArchiveBtn = () => {
    this.setState({ loading: true })
    if (this.state.subscriptionProfileData) {
      const { id } = this.state.subscriptionProfileData;
      this.handleSubscriptionArchiveApi(id);
    }
  }


  handleSubscriptionEditBtn = () => {
    const { subscriptionProfileData } = this.state;
    if (subscriptionProfileData) {
      const dataArray = this.state.subscriptionProfileData?.branch_name;
      const branchNameArray = dataArray ? (() => {
        try {
          return JSON.parse(dataArray);
        } catch (error) {
          console.error("Invalid JSON format in dataArray:", dataArray);
          return [];
        }
      })() : [];
      this.setState({
        brachesNames: branchNameArray,
        subscriptionName: subscriptionProfileData.name,
        selectPrice: subscriptionProfileData.price,
        description: subscriptionProfileData.description,
        selectDuration: subscriptionProfileData.duration === 0 ? 'Unlimited' : subscriptionProfileData.duration,
        selectNumberUnits: subscriptionProfileData.number_of_units === 0 ? 'Unlimited' : subscriptionProfileData.number_of_units,
        selectPer: subscriptionProfileData.per,
        selectPauses: subscriptionProfileData.pauses_allowed,
        unitLabel: subscriptionProfileData.name_of_units,
        branchSelectedIds: subscriptionProfileData.branch_ids
      })
      this.setState({
        openSubscriptionDetail: false,
        isEdit: true,
        mode: "edit"
        // isCreate:true
      })
    }
  }

  handleCloseMenu = () => {
    this.setState({ selectOption: null });
  };

  handleMenuOpen = (data: any) => {
    this.setState({ selectedSubscription: data, openArchiveSubscriptionDialog: true })
  }

  handleMenuRestoreOpen = (data: any) => {
    this.handleRestoreSubscriptionOpen()
    this.setState({ subscriptionProfileData: data })
  }

  checkCreateFormValidation = () => {

    const errors: { [key: string]: string } = {};
    const {
      subscriptionName,
      selectPrice,
      selectDuration,
      selectNumberUnits,
      selectPer,
      selectPauses,
      description,
      unitLabel,
      branchSelectedIds
    } = this.state;

    if (!subscriptionName) {
      errors.subscriptionName = "Required field is empty";
    }
    if (!description) {
      errors.description = "Required field is empty";
    }
    if (!branchSelectedIds || branchSelectedIds.length === 0) {
      errors.selectBranch = "Required field is empty";
    }
    if (!selectPrice) {
      errors.selectPrice = "Required field is empty";
    }
    if (selectDuration === "Select duration") {
      errors.selectDuration = "Required field is empty";
    }
    if (!selectNumberUnits || selectNumberUnits === "Select number of units") {
      errors.selectNumberUnits = "Required field is empty";
    }
    if (!selectPer || selectPer === "Select frequency") {
      errors.selectPer = "Required field is empty";
    }
    if (selectPauses === "Select number") {
      errors.selectPauses = "Required field is empty";
    }
    if (!unitLabel) {
      errors.unitLabel = "Required field is empty";
    }
    return errors;
  }

  handleActiveSubscriptionApi = () => {
    const isOwner = (localStorage.getItem("BusinessOwner") === "null" ? "false" : "true")
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.activeSubscriptionApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/get_active_subscriptions?is_business_owner=${isOwner}&branch_ids=${this.state.joinedId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllActiveSubscriptionListApiRes = (response: any) => {
    this.setState({ loading: false })
    if (response.message) {
      this.setState({ getActiveData: null })
    }
    let activeData;
    activeData = [...response?.data];
    this.setState({ getActiveData: activeData, });
  }

  handleArchivedSubscriptionApi = () => {
    const isOwner = (localStorage.getItem("BusinessOwner") === "null" ? "false" : "true")
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.archivedSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/get_archive_subscriptions?is_business_owner=${isOwner}&branch_ids=${this.state.joinedId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllArchivedSubscriptionListApiRes = (response: any) => {
    if (response.message) {
      this.setState({ getArchivedData: null, });
    }
    let archivedData;
    archivedData = [...response?.data];
    this.setState({ getArchivedData: archivedData, });
  }

  getDataArray() {
    const { getActiveData, getArchivedData, selectedOption } = this.state;

    switch (selectedOption) {
      case 'active':
        return getActiveData;
      case 'archived':
        return getArchivedData;
      default:
        return getActiveData;
    }
  }

  //create
  getSelectPrice() {
    const prices = [];
    prices.push({ value: "other", label: "Other" });
    let priceValue = 4.99;
    while (priceValue <= 99.99) {
      prices.push({
        value: priceValue.toFixed(2),
        label: `£${priceValue.toFixed(2)}`,
      });
      priceValue += 5;
    }
    while (priceValue <= 199.99) {
      prices.push({
        value: priceValue.toFixed(2),
        label: `£${priceValue.toFixed(2)}`,
      });
      priceValue += 10;
    }
    return prices;
  }

  getSelectDuration() {
    const numbers = [];
    for (let i = 1; i <= 52; i++) {
      numbers.push({
        value: `${i}`,
        label: `${i}`,
      });
    }
    return numbers;
  }

  getSelectPer() {
    return [
      {
        value: 'Day',
        label: 'Day',
      },
      {
        value: 'Month',
        label: 'Month',
      },
      {
        value: 'Week',
        label: 'Week',
      }
    ];
  }

  getSelectNumberUnit() {
    const numbers = [];
    for (let i = 1; i <= 100; i++) {
      numbers.push({
        value: `${i}`,
        label: `${i}`,
      });
    }
    return numbers;
  }

  getSelectPauses() {
    const pauses = [];
    for (let i = 0; i <= 11; i++) {
      pauses.push({
        value: `${i}`,
        label: `${i}`,
      });
    }
    return pauses;
  }

  handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const { name, value } = event.target;

    if (name === "selectPrice" && value === "other") {
      this.setState({ isOtherSelected: true });
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: value ? '' : prevState.errors[name],
      },
    }));

    if (name === "description" && value.length > 250) {
      const truncatedValue = value
        .split(/\s+/)
        .slice(0, 250)
        .join(" ");
      this.setState({
        [name]: truncatedValue,
        descriptionLength: truncatedValue.length,
      });
      return;
    }

    const updatedState: Partial<S> = {
      [name]: value,
    };
    if (name === "description") {
      updatedState.descriptionLength = value.length;
    }
    this.setState((prevState) => ({
      ...prevState,
      ...updatedState,
    }));
  };

  handleSubscriptionCreateBtn = () => {
    this.setState({
      isCreate: true,
      mode: 'create',
      errors: {},
      brachesNames: null,
      subscriptionName: "",
      selectPrice: "",
      description: "",
      selectDuration: "Select duration",
      selectNumberUnits: "Select number of units",
      selectPer: "Select frequency",
      selectPauses: "Select number",
      unitLabel: "",
      branchSelectedIds: [],
      selectBranch: "Select Branch"
    })
  }

  createSubscriptionAPI = (event: any) => {
    const isOwner = (localStorage.getItem("BusinessOwner") === "null" ? "false" : "true")
    event.preventDefault();
    const errors = this.checkCreateFormValidation();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ loading: true });

      const {
        description,
        selectPrice,
        selectNumberUnits,
        unitLabel,
        subscriptionName,
        selectDuration,
        selectPauses,
        selectPer,
        branchSelectedIds
      } = this.state;

      const bodyData: any = {
        description,
        name: subscriptionName,
        name_of_units: unitLabel,
        duration: selectDuration,
        number_of_units: selectNumberUnits,
        per: selectPer,
        branch_ids: branchSelectedIds,
        price: selectPrice,
        pauses_allowed: selectPauses !== 'Select number' ? selectPauses : ''
      };
      const token = this.state.logInToken;
      this.createSubscriptionApiId = sendAPIRequest(
        `bx_block_custom_user_subs/subscriptions?is_business_owner=${isOwner}&branch_ids=${this.state.joinedId}`,
        {
          method: configJSON.httpPostMethod,
          body: bodyData,
          headers: {
            "Content-Type": "application/json",
            "token": token
          }
        },
      );
    }
  }

  editSubscriptionAPI = (event: any) => {
    const isOwner = (localStorage.getItem("BusinessOwner") === "null" ? "false" : "true")
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const errors = this.checkCreateFormValidation();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      const {
        description,
        selectPrice,
        selectNumberUnits,
        unitLabel,
        subscriptionName,
        selectDuration,
        selectPauses,
        selectPer,
        branchSelectedIds
      } = this.state;
      this.setState({ loading: true })

      const bodyData = {
        description,
        name: subscriptionName,
        name_of_units: unitLabel,
        duration: selectDuration,
        number_of_units: selectNumberUnits,
        per: selectPer,
        branch_ids: branchSelectedIds,
        price: selectPrice,
        pauses_allowed: selectPauses !== 'Select number' ? selectPauses : ''
      }
      const token = this.state.logInToken;
      this.editSubscriptionApiId = sendAPIRequest(
        `bx_block_custom_user_subs/subscriptions/${this.state.subscriptionProfileData.id}?is_business_owner=${isOwner}&branch_ids=${this.state.joinedId}`,
        {
          method: configJSON.httpPutMethod,
          body: bodyData,
          headers: {
            "Content-Type": "application/json",
            "token": token
          }
        },
      );
    }
  }

  viewSubscriptionAPI = (id: any) => {
    this.setState({ loading: true, openSubscriptionDetail: true })
    const token = this.state.logInToken;
    this.viewSubscriptionApiId = sendAPIRequest(
      `bx_block_custom_user_subs/subscriptions/${id}`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          "Content-Type": "application/json",
          "token": token
        }
      },
    );
  }

  viewDetailsApiRes = (response: any) => {
    let data = {};
    const { id, attributes } = response?.data;
    data = { id, ...attributes };
    this.setState({
      openSubscriptionDetail: true,
      subscriptionProfileData: data
    });
    if (!attributes?.active) {
      this.setState({ isSubscriptionArchived: true });
    } else this.setState({ isSubscriptionArchived: false });
  }


  branchListApiCall = () => {

    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSelectBranchApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_branch/joined_business_user_branches'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRestoreData = () => {
    this.handleSubscriptionRestoreBtn()
  }

  SubscriptionWrapper = ({ children }: { children: React.ReactNode }) => {
    const gridtheme = createTheme({
      overrides: {
        MuiGrid: {
          'grid-xs-6': {
            flexBasis: '50%',
          },
          'spacing-xs-3': {
            '@media (max-width: 1000px)': {
              padding: '0px',
            },
            '@media (min-width: 1201px)': {
              // padding: '45px',
            },
          },
        }
      }
    });

    return <ThemeProvider theme={gridtheme} children={children}></ThemeProvider>
  }

  MainSubscriptionWrapper = ({ children }: { children: React.ReactNode }) => {
    const gridthemelg = createTheme({
      overrides: {
        MuiGrid: {
          'grid-lg-12': {
            maxWidth: '100% ',
            flexBasis: '50% !important',
            flexGrow: 0
          }
        }
      } as Overrides
    });

    return <ThemeProvider theme={gridthemelg} children={children}></ThemeProvider>
  }

  handleSelectBranchApiRes = (response: any) => {
    this.setState({ allBranches: response?.branch });
  }

  getAllBusinessApiRes = (responseJson: any) => {
    if (!responseJson.errors) {
      const businessid = responseJson?.data?.id;
      this.branchListApiCall()
      this.setState({
        business_id: businessid,
      })
    }
  }

  createApiRes = (response: any) => {
    if (!response.errors) {
      this.setState({ isEdit: false, isCreate: false, openSubscriptionDetail: false, mode: '', errors: {}, selectedBranches: [], selectAll: [], customPrice: '' });
      this.handleActiveSubscriptionApi()
    }
  }


  handleChangeBranches = (event: any) => {
    const { allBranches } = this.state;
    const selectedBranches = event.target.value;
    const arrayIds: any = [];

    if (selectedBranches.includes('All')) {
      if (this.state.selectAll.includes('All')) {
        this.setState({
          selectedBranches: [],
          selectAll: [],
          branchSelectedIds: [],
          errors: {
            ...this.state.errors,
            selectBranch: '',
          },
        });
      } else {
        const allBranchNames = allBranches.map((branch: any) => branch[0]?.name);
        const allBranchIds = allBranches.map((branch: any) => branch[0]?.id).filter((id: any) => id !== undefined);

        this.setState({
          selectedBranches: allBranchNames,
          selectAll: ['All'],
          branchSelectedIds: allBranchIds,
          errors: {
            ...this.state.errors,
            selectBranch: '',
          },
        });
      }
    }
    else {
      const selectedBranchIds = selectedBranches.map((name: string) => {
        const branch = allBranches.find((branch: any) => branch[0]?.name === name);
        if (branch) {
          return branch[0]?.id;
        }
      }).filter((id: any) => id !== undefined);

      if (this.state.selectedBranches.includes('All')) {
        this.setState({ selectedBranches: selectedBranches.filter((branch: any) => branch !== 'All') });
        this.setState({ selectAll: [''], branchSelectedIds: [] });
      } else {
        this.setState({ selectedBranches });
        this.setState({ selectAll: [''] });
        arrayIds.push(...selectedBranchIds);

        this.setState((prevState) => ({
          ...prevState,
          branchSelectedIds: arrayIds,
          errors: {
            ...prevState.errors,
            selectBranch: arrayIds ? '' : prevState.errors.selectBranch,
          },
        }));
      }
    }
  };


  handleChangeBranchesEdit = (event: any) => {
    const { allBranches, brachesNames } = this.state;
    const selectedBranches = event.target.value;
    const arrayIds: any = [];


    if (selectedBranches.includes('All')) {
      if (this.state.selectAll.includes('All')) {
        this.setState({
          brachesNames: [],
          selectAll: [],
          branchSelectedIds: [],
          errors: {
            ...this.state.errors,
            selectBranch: '',
          },
        });
      } else {
        const allBranchNames = allBranches.map((branch: any) => branch[0]?.name);
        const allBranchIds = allBranches.map((branch: any) => branch[0]?.id).filter((id: any) => id !== undefined);

        this.setState({
          brachesNames: allBranchNames,
          selectAll: ['All'],
          branchSelectedIds: allBranchIds,
          errors: {
            ...this.state.errors,
            selectBranch: '',
          },
        });
      }
    }
    else {

      const selectedBranchIds = selectedBranches.map((name: string) => {
        const branch = allBranches.find((branch: any) => branch[0]?.name === name);
        if (branch) {
          return branch[0].id;
        }
      }).filter((id: any) => id !== undefined);

      if (this.state.brachesNames.includes('All')) {
        this.setState({ brachesNames: selectedBranches.filter((branch: any) => branch !== 'All') });
        this.setState({ selectAll: [''], branchSelectedIds: [] });
      } else {
        this.setState({ brachesNames: selectedBranches });
        this.setState({ selectAll: [''] });
        arrayIds.push(...selectedBranchIds);

        this.setState((prevState) => ({
          ...prevState,
          branchSelectedIds: arrayIds,
          errors: {
            ...prevState.errors,
            selectBranch: arrayIds ? '' : prevState.errors.selectBranch,
          },
        }));
      }
    }

  };

  validateSelectPrice(value: string) {
    const numericValue = parseFloat(value);

    if (value === "") {
      this.setState({ errors: { selectPrice: "Please enter a value" } });
      return false;
    }
    if (numericValue < 0.5) {
      this.setState({ errors: { selectPrice: "Please enter the value above 0.50" } });
      return false;
    }
    return true;
  }

  handleChange = (e: { target: { value: string; }; }) => {
    let value = e.target.value.replace("£", "").trim(); 

    const regex = /^\d{0,4}(\.\d{0,2})?$/; 
    if (value === "") {
      this.setState({ rawInput: value, errors: { selectPrice: "" } });
    } else if (!regex.test(value)) {
      this.setState({ errors: { selectPrice: "Please enter a valid value" } });
    } else if (parseFloat(value) < 0.5) {
      this.setState({ errors: { selectPrice: "Please enter the value above £0.50" } });
    } else if (parseFloat(value) > 9999.99) {
      this.setState({ errors: { selectPrice: "Please enter value up to £9999.99" } });
    } else {
      this.setState({ rawInput: value, errors: { selectPrice: "" } });
    }
  };

  handleBlur = () => {
    const { rawInput } = this.state;

    if (this.validateSelectPrice(rawInput)) {
      this.setState({ selectPrice: rawInput, errors: { selectPrice: "" } });
    } else {
      this.setState({ rawInput: this.state.selectPrice });
    }
  };

  createbackToSubscription = () => {
    this.handleActiveSubscriptionApi()
    this.setState({ isCreate: false, mode: "", isActive: 'active' })
  }

  editbackToSubscription = () => {
    this.handleActiveSubscriptionApi()
    this.setState({ isEdit: false, mode: "", isActive: 'active', openSubscriptionDetail: true })
    this.setState({ mode: "", isCreate: false, description: "", selectBranch: "Select branch", selectPrice: "", selectNumberUnits: "Select number of units", unitLabel: "", subscriptionName: "", selectDuration: "Select duration", selectPer: "Select frequency", selectPauses: "Select number", selectedBranches: [] });
  }

  backToHome = () => {
    this.send(getNavigationMessage("LandingPage", this.props));
  }

  ActiveButton = styled(Button)({
    color: 'white', borderRadius: '28px',
    border: '1px solid #fff',
    textTransform: 'capitalize' as 'capitalize',
    textAlign: 'center' as 'center',
    fontSize: '18px',
    height: '56px',
    width: '225px',
    "@media (max-width: 560px)": {
      fontSize: '16px',
      height: '45px',
      width: '180px',
    }
  });

  IsActiveButton = styled(Button)({
    color: 'black', borderRadius: '28px',
    border: '1px solid #fff', textTransform: 'capitalize' as 'capitalize',
    textAlign: 'center' as 'center',
    fontSize: '18px',
    height: '56px',
    width: '225px',
    backgroundColor: 'white',
    "&:hover": {
      backgroundColor: "white",
      color: 'black'
    },
    "@media (max-width: 560px)": {
      fontSize: '16px',
      height: '45px',
      width: '180px',
    }
  });

  CreateButton = styled(Button)({
    color: 'white',
    borderRadius: '28px',
    border: '1px solid #fff',
    textTransform: 'capitalize' as 'capitalize',
    textAlign: 'center' as 'center',
    marginTop: '60px !important',
    fontSize: '18px',
    height: '56px',
    width: '225px',
    marginRight: '50px',
    "&:hover": {
      background: 'white',
      color: 'black'
    },
  });



  editApiRes = (responseJson: any) => {
    if (!responseJson.errors) {
      this.handleArchivedSubscriptionApi()
      this.handleActiveSubscriptionApi()
      this.setState({ isEdit: false, isCreate: false, mode: "", openSubscriptionDetail: false, selectAll: [] });
    }
  }

  activeOption = () => {
    this.handleActiveSubscriptionApi();
    this.setState({ selectedOption: 'active' })
  }

  archivedOption = () => {
    this.handleArchivedSubscriptionApi();
    this.setState({ selectedOption: 'archived' })
  }


  showHeading = (webStyle: any) => {
    const renderHeadingBox = (
      onClick: () => void,
      text: string,
      style: any,
      dataTestId: string
    ) => (
      <Box
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          marginBottom: 'clamp(15px, 48px, 4vw)',
        }}
      >
        <ArrowBackIcon
          onClick={onClick}
          style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }}
          data-test-id={dataTestId}
        />
        <Typography style={style}>{text}</Typography>
      </Box>
    );

    const dataArray = this.getDataArray();

    return (
      <>
        {this.state.isCreate && (
          <Container maxWidth="lg">
            {renderHeadingBox(
              this.createbackToSubscription,
              'Create New Subscription',
              webStyle.headingStyleCreateBtn,
              'arrow-back-icon-btn-create'
            )}
          </Container>
        )}

        {this.state.isEdit && (
          <Container maxWidth="lg">
            {renderHeadingBox(
              this.editbackToSubscription,
              'Edit Subscription',
              webStyle.headingStyleCreateBtn,
              'arrow-back-icon-btn'
            )}
          </Container>
        )}

        {this.state.openSubscriptionDetail && (
          <Container
            maxWidth="lg"
            style={{ padding: '0 clamp(10px, 80px, 8%)', margin: 0 }}
          >
            {renderHeadingBox(
              this.handleBackToSubscriptionList,
              'View Details',
              webStyle.headingStyle,
              'backtosubscription'
            )}
          </Container>
        )}

        {!this.state.isCreate &&
          !this.state.openSubscriptionDetail &&
          !this.state.isEdit && (
            <this.MainSubscriptionWrapper>
              <Box>
                <Container style={{ maxWidth: '100%', margin: '0' }}>
                  <div>
                    <Box
                      style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <ArrowBackIcon
                        onClick={this.backToHome}
                        style={{
                          color: '#fff',
                          cursor: 'pointer',
                          fontSize: '32px',
                        }}
                        data-test-id="arrow-back-icon-btn"
                      />
                      <Typography
                        style={{
                          ...webStyle.headingStyleCreateBtn,
                        }}
                      >
                        Subscriptions
                      </Typography>
                    </Box>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          paddingLeft: '53px',
                          gap: '20px',
                        }}
                      >
                        <div>
                          {this.state.selectedOption === 'active' ? (
                            <this.IsActiveButton
                              data-test-id="isActive"
                              fullWidth
                              onClick={this.activeOption}
                            >
                              Active
                            </this.IsActiveButton>
                          ) : (
                            <this.ActiveButton
                              data-test-id="active"
                              fullWidth
                              onClick={this.activeOption}
                            >
                              Active
                            </this.ActiveButton>
                          )}
                        </div>
                        <div>
                          {this.state.selectedOption === 'archived' ? (
                            <this.IsActiveButton
                              fullWidth
                              onClick={this.archivedOption}
                            >
                              Archived
                            </this.IsActiveButton>
                          ) : (
                            <this.ActiveButton
                              fullWidth
                              onClick={this.archivedOption}
                            >
                              Archived
                            </this.ActiveButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </Box>
              <Box
                className="subscription-list"
                style={{ paddingLeft: '30px', paddingTop: '10px' }}
              >
                <this.SubscriptionWrapper>
                  <Container style={{ maxWidth: '100%', margin: '0' }}>
                    <Grid
                      container
                      spacing={3}
                      style={{ maxWidth: '100%', margin: 0 }}
                    >
                      {dataArray === null && (
                        <Container
                          maxWidth="xs"
                          style={{
                            textAlign: 'center',
                            marginTop: '80px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              textTransform: 'capitalize',
                              fontSize: '24px',
                            }}
                          >
                            No Subscriptions Found
                          </div>
                          <div>
                            <CustomeButton
                              text="Create New Subscription"
                              style={webStyle.createNewNoData}
                              onclicked={() => {
                                this.handleSubscriptionCreateBtn();
                              }}
                              data-test-id="create-subs"
                              primary={true}
                            />
                          </div>
                        </Container>
                      )}

                      {Array.isArray(dataArray) &&
                        dataArray?.map((list: any, i: any) => {
                          return (
                            <Grid key={i} item xs={12} sm={6} md={4} lg={4}>
                              <div
                                style={
                                  list.attributes.active === false ||
                                    this.state.selectedOption === 'active'
                                    ? webStyle.subscriptionListStyle
                                    : webStyle.subscriptionList
                                }
                              >
                                <strong style={{ textTransform: 'capitalize' }}>
                                  {' '}
                                  {list.attributes.name}
                                </strong>
                                <div>
                                  <p style={{ marginTop: '25px' }}>
                                    <b>&#163;</b> <b>{list.attributes.price}</b>
                                    /month
                                  </p>
                                  <p style={{ marginTop: '20px' }}>
                                    {list.attributes.number_of_units !== 0
                                      ? list.attributes.number_of_units
                                      : 'Unlimited'}{' '}
                                    {list.attributes.name_of_units} per{' '}
                                    {list.attributes.per}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    ...webStyle.listBtnGroup,
                                    marginTop: '25px',
                                  }}
                                >
                                  <div>
                                    {list.attributes.active === false ? (
                                      <CustomeButton
                                        data-test-id="status_btnView"
                                        style={{
                                          ...webStyle.statusBtn,
                                          textTransform: 'none',
                                          color: 'white',
                                        }}
                                        text="Restore"
                                        onclicked={() => {
                                          this.handleMenuRestoreOpen(list);
                                          this.handleArchivedSubscriptionApi();
                                        }}
                                        primary={false}
                                      />
                                    ) : (
                                      <CustomeButton
                                        data-test-id="statusBtn"
                                        style={{
                                          ...webStyle.statusBtn,
                                          textTransform: 'none',
                                          color: 'white',
                                        }}
                                        text="Archive"
                                        onclicked={() => {
                                          this.handleMenuOpen(list);
                                        }}
                                        primary={false}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <CustomeButton
                                      data-test-id="view-details"
                                      style={{
                                        ...webStyle.viewDetails,
                                        textTransform: 'none',
                                      }}
                                      text="View Details"
                                      onclicked={() => {
                                        this.handleViewDetailBtn(list);
                                      }}
                                      primary={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Container>
                  {dataArray && dataArray.length > 0 && (
                    <Container maxWidth="xl">
                      <div style={webStyle.flexEnd}>
                        <Button
                          data-test-id="create-new"
                          onClick={() => {
                            this.handleSubscriptionCreateBtn();
                          }}
                          style={webStyle.createNew}
                        >
                          Create New Subscription
                        </Button>
                      </div>
                    </Container>
                  )}
                </this.SubscriptionWrapper>
              </Box>
            </this.MainSubscriptionWrapper>
          )}
      </>
    );
  };


  renderSelectBranchUi = (webStyle: any, selectTheme: any, MenuProps: any, value: any, handleChange: any) => {
    return (
      <ThemeProvider theme={selectTheme}>
        <InputLabel style={webStyle.inputLabel}>Select Branch</InputLabel>
        <FormControl fullWidth>
          <ErrorTooltip
            placement="bottom-start"
            PopperProps={{
              disablePortal: true,
            }}
            open={!!this.state.errors.selectBranch}
            disableFocusListener
            arrow
            disableTouchListener
            disableHoverListener
            title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectBranch}</>}
          >
            <Select fullWidth
              labelId="demo-mutiple-checkbox-label" id="demo-mutiple-checkbox"
              multiple displayEmpty value={value} onChange={handleChange} input={<Input style={{ paddingLeft: '10px' }} fullWidth placeholder="Select Branch" />}
              data-test-id="createselectbranch"
              renderValue={(selected: any) => {
                if (Array.isArray(selected)) {
                  if (selected.length === 0) { return <div style={{ color: 'black', paddingLeft: '10px' }}>Select Branch</div>; } else if (selected.length <= 2) {
                    return selected.join(', ');
                  } else {
                    return (
                      <>  {selected.slice(0, 2).join(', ')}  ...<span style={{ textDecoration: 'underline', paddingLeft: '5px' }}> +{selected.length - 2} more</span></>
                    );
                  }
                } return null;
              }} MenuProps={MenuProps}
            > <MenuItem key="All" value="All" style={{ backgroundColor: '#F4CD46' }}> <Checkbox icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxOutlined />} checked={this.state.selectAll.includes('All')} /> <ListItemText primary={<div style={{ paddingLeft: '10px' }}>All</div>} /> </MenuItem>
              {Array.isArray(this.state.allBranches) && this.state.allBranches.map((name: any) => {
                const branchName = name?.[0]?.name;

                return (
                  <MenuItem key={branchName} value={branchName}>   <Checkbox icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBoxOutlined />} checked={value?.indexOf(branchName) > -1} /> <ListItemText primary={<div style={{ paddingLeft: '15px' }}>{branchName}</div>} /> <hr /></MenuItem>
                );
              })}
            </Select>
          </ErrorTooltip>
        </FormControl>
      </ThemeProvider>
    )
  }


  renderCreateSubscriptionUi = (webStyle: any, selectTheme: any, MenuProps: any) => {
    const duration = this.getSelectDuration()
    const per = this.getSelectPer()
    const pauses = this.getSelectPauses()
    return (
      <form onSubmit={this.createSubscriptionAPI}>
        <Container maxWidth="md">
          <Container maxWidth="md" style={{ padding: 0 }}>
            <Box>
              <Grid container spacing={2}><Grid item xs={12} lg={6} md={6}> <InputLabel style={webStyle.inputLabel}>Subscription Name</InputLabel>
                <CustomTextField
                  name="subscriptionName"
                  placeholder=""
                  dataTestId="txtInputSubscriptionName"
                  istrue={true}
                  value={this.state.subscriptionName}
                  error={this.state.errors.subscriptionName}
                  fullWidth
                  onChange={this.handleInputChange}
                />
              </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  {this.renderSelectBranchUi(webStyle, selectTheme, MenuProps, this.state.selectedBranches, this.handleChangeBranches)}
                </Grid>
                <Grid item xs={12} lg={12} md={12} style={{ height: '165px' }}>
                  <InputLabel style={webStyle.inputLabel}>Description
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={
                        <div style={{ color: 'black' }}>
                          <InfoIcon style={webStyle.tootipIconStyle} />
                          {configJSON.discriptionTooltipText}
                        </div>
                      }
                    >
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={!!this.state.errors.description}
                    disableFocusListener
                    arrow
                    placement="bottom-start"
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.description}</>}
                  >
                    <TextareaAutosize name="description" data-test-id="description" value={this.state.description}
                      maxLength={250} style={{ width: '100%', height: '120px', background: 'white', resize: 'none' }} onChange={this.handleInputChange} />
                  </ErrorTooltip>
                  <TextLimitBox>
                    {<div style={{ color: this.state.descriptionLength === 0 ? 'black' : '#18CC18' }}>{this.state.descriptionLength}<span style={{ color: 'black' }}>/250</span></div>}
                  </TextLimitBox>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <InputLabel style={{ ...webStyle.inputLabel }}>Price (per month)
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={
                        <div style={{ color: 'black' }}>
                          <InfoIcon style={webStyle.tootipIconStyle} />
                          {configJSON.priceTooltipText}
                        </div>
                      }
                    >
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={!!this.state.errors.selectPrice}
                    arrow
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPrice}</>}
                  >
                    <TextField
                      fullWidth
                      data-test-id="selectPrice"
                      id="standard-select-currency"
                      type="text"
                      variant="filled"
                      name="selectPrice"
                      size="small"
                      onBlur={() => {
                        this.setState({ errors: { selectPrice: '' } });
                      }}
                      onChange={(e) => {
                        let value = e.target.value.replace("£", "").trim();

                        const regex = /^\d{0,4}(\.\d{0,2})?$/;

                        if (value === "") {
                          this.setState({ selectPrice: "", errors: { selectPrice: "" } });
                        } else if (!regex.test(value)) {
                          this.setState({ errors: { selectPrice: "Please enter a valid value" } });
                        } else if (parseFloat(value) < 0.5) {
                          this.setState({ errors: { selectPrice: "Please enter the value above £0.50" } });
                        } else if (parseFloat(value) > 9999.99) {
                          this.setState({ errors: { selectPrice: "Please enter value up to £9999.99" } });
                        } else {
                          this.setState({ selectPrice: value, errors: { selectPrice: "" } });
                        }
                      }}
                      error={!!this.state.errors.selectPrice}
                      style={webStyle.inputTextfiled}
                      inputProps={{
                        inputMode: "decimal",
                      }}
                      value={this.state.selectPrice ? `£${this.state.selectPrice}` : ""}
                    />
                  </ErrorTooltip>
                </Grid>

                <Grid item xs={12} lg={6} md={6} >
                  <InputLabel style={{ ...webStyle.inputLabel }}>Duration (months)
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={<div style={{ color: 'black' }}> <InfoIcon style={webStyle.tootipIconStyle} />
                        {configJSON.durationTooltipText}</div>}>
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={!!this.state.errors.selectDuration}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectDuration}</>}
                  >
                    <TextField id="standard-select-currency"
                      fullWidth select data-test-id="selectDuration" style={webStyle.inputTextfiled} variant="filled" name="selectDuration" value={this.state.selectDuration}
                      size="small"
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.selectDuration}
                      SelectProps={{
                        MenuProps: MenuProps,
                        renderValue: (selected: any) => {
                          if (selected === "Select duration") {
                            return <div style={{ color: 'black' }}>Select duration</div>;
                          } return selected;
                        },
                      }}
                    >
                      <MenuItem
                        key="Unlimited(auto renew until cancel)"
                        value="Unlimited"
                        style={{ backgroundColor: '#F4CD46', padding: 0, whiteSpace: 'unset' }} >
                        <ListItemText primary={<div style={{ paddingLeft: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Unlimited</span> (auto renew until cancelled)</div>} />
                      </MenuItem>
                      {duration.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                          <Divider />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ErrorTooltip>
                </Grid>
                {this.numberOfInputs(webStyle)}
                <Grid item xs={12} lg={6} md={6} >
                  <InputLabel style={webStyle.inputLabel}>Per
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={<div style={{ color: 'black' }}>
                        <InfoIcon style={webStyle.tootipIconStyle} />
                        {configJSON.perTooltipText}</div>}>
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={!!this.state.errors.selectPer}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPer}</>}
                  >
                    <TextField
                      id="standard-select-currency" select
                      style={webStyle.inputTextfiled}
                      fullWidth data-test-id="selectFrequency"
                      variant="filled" name="selectPer"
                      value={this.state.selectPer} size="small"
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.selectPer}
                      SelectProps={{
                        renderValue: (selected: any) => {
                          if (selected === "Select frequency") {
                            return <div style={{ color: 'black' }}>Select frequency</div>;
                          }
                          return selected;
                        },
                      }}
                    >
                      {per.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}
                          <Divider />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ErrorTooltip>
                </Grid>
                <Grid item xs={12} lg={6} md={6} >
                  <InputLabel style={webStyle.inputLabel}>Unit Label
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={<div style={{ color: 'black' }}>
                        <InfoIcon style={webStyle.tootipIconStyle} />
                        {configJSON.unitsLabelTooltipText}</div>} >
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <CustomTextField
                    name="unitLabel"
                    istrue={true}
                    placeholder=""
                    dataTestId="txtInputBusinessName"
                    value={this.state.unitLabel}
                    error={this.state.errors.unitLabel}
                    fullWidth
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6} >
                  <InputLabel style={webStyle.inputLabel}>Pauses Allowed
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={<div style={{ color: 'black' }}><InfoIcon style={webStyle.tootipIconStyle} />  {configJSON.pausesTooltipText}</div>} >
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={!!this.state.errors.selectPauses}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPauses}</>}
                  >
                    <TextField id="standard-select-currency"
                      select
                      fullWidth
                      style={webStyle.inputTextfiled} data-test-id="selectNumber"
                      variant="filled" name="selectPauses"
                      error={!!this.state.errors.selectPauses}
                      value={this.state.selectPauses} size="small" onChange={this.handleInputChange}

                      SelectProps={{
                        renderValue: (selected: any) => {
                          if (selected === "Select number") {
                            return <div style={{ color: 'black' }}>Select number</div>;
                          } return selected;
                        },
                      }}
                    >
                      {pauses.map((option) => (
                        <MenuItem key={option.value} value={option.value}> {option.label}
                          <Divider />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ErrorTooltip>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Container>
        <Container maxWidth="md" style={{ textAlign: 'center', padding: 0 }}>
          <div style={webStyle.createSubscriptionflexEnd}>
            <this.CreateButton data-test-id="fetchAllSubscriptionListData" type="submit" >Create</this.CreateButton>
          </div>
        </Container>
      </form>
    )
  }

  numberOfInputs = (webStyle: any) =>{
    const numberUnits = this.getSelectNumberUnit();
    return (
      <Grid item xs={12} lg={6} md={6}>
        <InputLabel style={webStyle.inputLabel}>Number of Units
          <LightTooltip
            placement="bottom-start"
            arrow
            title={<div style={{ color: 'black' }}> <InfoIcon style={webStyle.tootipIconStyle} />
              {configJSON.unitsTooltipText}</div>}>
            <InfoIcon style={webStyle.infoIconStyle} />
          </LightTooltip>
        </InputLabel>
        <ErrorTooltip
          open={!!this.state.errors.selectNumberUnits}
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          arrow
          disableHoverListener
          placement="bottom-start"
          disableTouchListener
          title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectNumberUnits}</>}
        >
          <TextField
            id="standard-select-currency" fullWidth
            select style={webStyle.inputTextfiled}
            data-test-id="selectNOU" variant="filled" name="selectNumberUnits"
            value={this.state.selectNumberUnits} size="small"
            onChange={this.handleInputChange}
            error={!!this.state.errors.selectNumberUnits}
            SelectProps={{
              renderValue: (selected: any) => {
                if (selected === "Select number of units") {
                  return <div style={{ color: 'black' }}>Select number of units</div>;
                } return selected;
              },
            }}
          >
            <MenuItem
              value="Unlimited"
              key="Unlimited(auto renew until cancel)"
              style={{ padding: 0, backgroundColor: '#F4CD46',  whiteSpace: 'unset' }} >
              <ListItemText primary={<div
                style={{ paddingLeft: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Unlimited</span> </div>} />
            </MenuItem>
            {numberUnits?.map((option: any) => {
              return (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                  <Divider />
                </MenuItem>

              )
            })}

          </TextField>
        </ErrorTooltip>
      </Grid>
    )
  }

  renderLoader = () => {
    return (
      <>
        {this.state.loading && <SpinnerLoader />}
      </>
    )
  }

  renderEditUi = (webStyle: any, selectTheme: any, MenuProps: any) => {
    const duration = this.getSelectDuration()
    const per = this.getSelectPer()
    const numberUnits = this.getSelectNumberUnit()
    const pauses = this.getSelectPauses()

    return (
      <>
        <form onSubmit={this.editSubscriptionAPI}>
          <Container maxWidth="md">  <Container maxWidth="md" style={{ padding: 0 }}>
            <Box>  <Grid container spacing={2}>    <Grid item xs={12} lg={6} md={6}>
              <InputLabel style={webStyle.inputLabel}>Subscription Name</InputLabel>
              <CustomTextField
                name="subscriptionName"
                istrue={true}
                placeholder=""
                dataTestId="txtInputBusinessName"
                value={this.state.subscriptionName}
                error={this.state.errors.subscriptionName}
                fullWidth
                onChange={this.handleInputChange}
              />
            </Grid>
              <Grid item xs={12} lg={6} md={6}>
                {this.renderSelectBranchUi(webStyle, selectTheme, MenuProps, this.state.brachesNames, this.handleChangeBranchesEdit)}

              </Grid>
              <Grid item xs={12} lg={12} md={12} style={{ height: '165px' }}>
                <InputLabel style={webStyle.inputLabel}>Description
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={<div style={{ color: 'black' }}><InfoIcon style={webStyle.tootipIconStyle} /> {configJSON.discriptionTooltipText}</div>}>
                    <InfoIcon style={webStyle.infoIconStyle} /></LightTooltip>
                </InputLabel>
                <ErrorTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  open={!!this.state.errors.description}
                  disableFocusListener
                  arrow
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.description}</>}
                >
                  <TextareaAutosize name="description"
                    data-test-id="description"
                    value={this.state.description} maxLength={250}
                    style={{ width: '100%', height: '120px', background: 'white', resize: 'none' }}
                    onChange={this.handleInputChange} />
                </ErrorTooltip>
                <TextLimitBox>
                  {<div style={{ color: this.state.subscriptionProfileData?.description.length === 0 ? 'black' : '#18CC18' }}>{this.state.subscriptionProfileData?.description.length}<span style={{ color: 'black' }}>/250</span></div>}
                </TextLimitBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <InputLabel style={{ ...webStyle.inputLabel }}>Price (per month)
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={
                      <div style={{ color: 'black' }}>
                        <InfoIcon style={webStyle.tootipIconStyle} />
                        {configJSON.priceTooltipText}
                      </div>
                    }
                  >
                    <InfoIcon style={webStyle.infoIconStyle} />
                  </LightTooltip>
                </InputLabel>
                <ErrorTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  arrow
                  open={!!this.state.errors.selectPrice}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPrice}</>}
                >
                  <TextField
                    fullWidth
                    value={this.state.rawInput ? `£${this.state.rawInput}` : ""}
                    data-test-id="selectPriceedit"
                    id="standard-select-currency"
                    type="text"
                    error={!!this.state.errors.selectPrice}
                    style={webStyle.inputTextfiled}
                    variant="filled"
                    inputProps={{
                      inputMode: "decimal",
                    }}
                    size="small"
                    name="selectPrice"
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                  />


                </ErrorTooltip>
              </Grid>
              <Grid item xs={12} lg={6} md={6} >
                <>
                  <InputLabel style={{ ...webStyle.inputLabel }}>Duration (months)
                    <LightTooltip
                      placement="bottom-start"
                      arrow
                      title={<div style={{ color: 'black' }}> <InfoIcon style={webStyle.tootipIconStyle} /> {configJSON.durationTooltipText}</div>}>
                      <InfoIcon style={webStyle.infoIconStyle} />
                    </LightTooltip>
                  </InputLabel>
                  <ErrorTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={!!this.state.errors.selectDuration}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectDuration}</>}
                  >
                    <TextField
                      id="standard-select-currency"
                      fullWidth
                      select
                      data-test-id="selectDuration"
                      style={webStyle.inputTextfiled}
                      variant="filled"
                      name="selectDuration"
                      value={this.state.selectDuration}
                      size="small"
                      onChange={this.handleInputChange}
                      SelectProps={{
                        MenuProps: MenuProps, renderValue: (selected: any) => {
                          if (selected === "Select duration") {
                            return <div style={{ color: 'black' }}>Select duration</div>;
                          }
                          return selected;
                        },
                      }}
                    >
                      <MenuItem
                        key="Unlimited(auto renew until cancel)" value="Unlimited"
                        style={{ backgroundColor: '#F4CD46' }}
                      >    <ListItemText primary={<div style={{ paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Unlimited</span> (auto renew until cancelled)</div>} />
                      </MenuItem>
                      {duration.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ErrorTooltip>
                </>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <InputLabel style={webStyle.inputLabel}>Number of Units
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={<div style={{ color: 'black' }}> <InfoIcon style={webStyle.tootipIconStyle} /> {configJSON.unitsTooltipText}</div>}>
                    <InfoIcon style={webStyle.infoIconStyle} />
                  </LightTooltip>
                </InputLabel>
                <ErrorTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  open={!!this.state.errors.selectNumberUnits}
                  disableFocusListener
                  arrow
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectNumberUnits}</>}
                >
                  <TextField
                    id="standard-select-currency"
                    fullWidth
                    select
                    style={webStyle.inputTextfiled}
                    data-test-id="selectNOU"
                    variant="filled"
                    name="selectNumberUnits"
                    value={this.state.selectNumberUnits}
                    size="small" onChange={this.handleInputChange} SelectProps={{
                      renderValue: (selected: any) => {
                        if (selected === "Select number of units") {
                          return <div style={{ color: 'black' }}>Select number of units</div>;
                        }
                        return selected;
                      },
                    }}
                  >
                    <MenuItem
                      key="Unlimited"
                      value="Unlimited"
                      style={{ backgroundColor: '#F4CD46', padding: 0, whiteSpace: 'unset' }} >
                      <ListItemText primary={<div style={{ paddingLeft: '10px' }}>
                        <span style={{ fontWeight: 'bold' }}>Unlimited</span> </div>} />
                    </MenuItem>
                    {numberUnits.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        <Divider />
                      </MenuItem>
                    ))}

                  </TextField>
                </ErrorTooltip>
              </Grid>
              <Grid item xs={12} lg={6} md={6} >
                <InputLabel style={webStyle.inputLabel}>Per
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={<div style={{ color: 'black' }}><InfoIcon style={webStyle.tootipIconStyle} /> {configJSON.perTooltipText}</div>}>
                    <InfoIcon style={webStyle.infoIconStyle} />
                  </LightTooltip>
                </InputLabel>
                <ErrorTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  open={!!this.state.errors.selectPer}
                  disableFocusListener
                  arrow
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPer}</>}
                >
                  <TextField
                    id="standard-select-currency"
                    select
                    style={webStyle.inputTextfiled} fullWidth data-test-id="selectFrequency" variant="filled"
                    name="selectPer"
                    value={this.state.selectPer}
                    size="small"
                    onChange={this.handleInputChange} SelectProps={{
                      renderValue: (selected: any) => {
                        if (selected === "Select frequency") {
                          return <div style={{ color: 'black' }}>Select frequency</div>;
                        }
                        return selected;
                      },
                    }}
                  >
                    {per.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        <Divider />
                      </MenuItem>
                    ))}
                  </TextField>
                </ErrorTooltip>
              </Grid>
              <Grid item xs={12} lg={6} md={6} >
                <InputLabel style={webStyle.inputLabel}>Unit Label
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={<div style={{ color: 'black' }}><InfoIcon style={webStyle.tootipIconStyle} /> {configJSON.unitsLabelTooltipText}</div>} >
                    <InfoIcon style={webStyle.infoIconStyle} />
                  </LightTooltip>
                </InputLabel>
                <CustomTextField
                  name="unitLabel"
                  istrue={true}
                  placeholder=""
                  dataTestId="txtInputBusinessName"
                  value={this.state.unitLabel}
                  error={this.state.errors.unitLabel}
                  fullWidth
                  onChange={this.handleInputChange}
                />

              </Grid>
              <Grid item xs={12} lg={6} md={6} >
                <InputLabel style={webStyle.inputLabel}>Pauses Allowed
                  <LightTooltip
                    placement="bottom-start"
                    arrow
                    title={<div style={{ color: 'black' }}><InfoIcon style={webStyle.tootipIconStyle} />{configJSON.pausesTooltipText}</div>} >
                    <InfoIcon style={webStyle.infoIconStyle} />
                  </LightTooltip></InputLabel>
                <ErrorTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  open={!!this.state.errors.selectPauses}
                  disableFocusListener
                  arrow
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.selectPauses}</>}
                >
                  <TextField
                    id="standard-select-currency"
                    select
                    fullWidth style={webStyle.inputTextfiled}
                    data-test-id="selectNumber" variant="filled"
                    name="selectPauses"
                    value={this.state.selectPauses}
                    size="small" onChange={this.handleInputChange}
                    SelectProps={{
                      renderValue: (selected: any) => {
                        if (selected === "Select number") {
                          return <div style={{ color: 'black' }}>Select number</div>;
                        }
                        return selected;
                      },
                    }}
                  >
                    {pauses.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        <Divider />
                      </MenuItem>
                    ))}
                  </TextField>
                </ErrorTooltip>
              </Grid>
            </Grid>
              </Box>
          </Container>
          </Container>
          <Container maxWidth="md" style={{ textAlign: 'center', padding: 0 }}>
            <div style={webStyle.createSubscriptionflexEnd}>
              <this.CreateButton type="submit" >Save</this.CreateButton>
            </div>
          </Container>
        </form>
      </>
    )
  }

  viewDetails = (webStyle: any) => {
    if (this.state.openSubscriptionDetail) {
      return (
        <>
          <Box className="subscription-details-container-main">
            <Box className="subscription-profile-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 'clamp(10px, 45px, 3vw)' }}>
              <Box className='subscription-name'>
                <Typography style={{ color: '#fff', fontSize: '2.5em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textTransform: 'capitalize' }}>{`${this.state.subscriptionProfileData?.name}`}</Typography>
              </Box>
              <Box className='subscription-plan'>
                <Typography style={{ color: '#fff', fontSize: '1.8em', fontWeight: 200, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>
                  <span style={{ color: '#fff', fontSize: '1.75em', fontWeight: 700, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>&#163;{`${this.state.subscriptionProfileData?.price}/`}</span>
                  month </Typography>
              </Box>
              <Box className='subscription-desc' style={{ width: '100%' }}>
                <Typography style={{ color: '#fff', fontSize: '1.5em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px', marginBottom: 'clamp(0px, 15px, 1vw)', textAlign: 'center' }}>About this Plan</Typography>
                <Typography style={{ color: '#fff', fontSize: '1.4em', fontWeight: 200, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textAlign: 'center', padding: '0 10%' }}>{this.state.subscriptionProfileData?.description}</Typography>
              </Box>
            </Box>
            <Box className="subscription-description-container" sx={webStyle.subscriptionDescriptionContainerStyle}>
              <Box>
                <Typography style={webStyle.descHeadingStyle}>What you get</Typography>
                <Typography style={webStyle.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.number_of_units !== 0 ? this.state.subscriptionProfileData?.number_of_units : 'Unlimited'} ${this.state.subscriptionProfileData?.name_of_units} per ${this.state.subscriptionProfileData?.per}`}</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.descHeadingStyle}>Duration</Typography>
                <Typography style={webStyle.descHeadingValueStyle}>{this.state.subscriptionProfileData?.duration == 0 ? 'Unlimited' : `${this.state.subscriptionProfileData?.duration} Months`}</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.descHeadingStyle}>Pauses</Typography>
                <Typography style={webStyle.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.pauses_allowed}`}</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.descHeadingStyle}>Valid Branches</Typography>
                <Tooltip title={this.getBranchNameList(this.state.subscriptionProfileData?.branch_name)}
                  data-test-id="branch-name-tooltip"
                  arrow
                  PopperProps={{
                    popperOptions: {
                      modifiers: {
                        offset: {
                          offset: '20, -15',
                        },
                      },
                    },
                  }}
                  disableFocusListener
                  style={webStyle.tooltipStyle}>
                  <Typography style={{ ...webStyle.descHeadingValueStyle, cursor: 'pointer' }} data-test-id="valid-branch-data">
                    {this.state.subscriptionProfileData?.valid_branches}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            <Box className="subscription-buttons-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', gap: '20px', flexWrap: 'wrap' }}>

              {this.state.isSubscriptionArchived ?
                <CustomeButton primary={false} data-test-id="subscription-detail-restore-btn" text={"Restore"} style={{ ...webStyle.buttonCommonStyle, color: '#fff', border: '1px solid #FFF' }} onclicked={this.handleRestoreSubscriptionOpen} />
                :
                <>
                  <CustomeButton primary={false} data-test-id="subscription-detail-archive-btn" text={"Archive"} style={{ ...webStyle.buttonCommonStyle, border: '1px solid #FFF', color: '#fff' }} onclicked={() => {
                    this.handleMenuOpen(this.state.subscriptionProfileData)
                  }} />

                  <CustomeButton primary={true} data-test-id="subscription-detail-edit-btn" text={"Edit subscription"} style={{ ...webStyle.buttonCommonStyle, color: '#fff', border: '1px solid #F4CD46' }} onclicked={this.handleSubscriptionEditBtn} />
                </>
              }
            </Box>
          </Box>
        </>
      )
    }
  }

  renderEditCreateUi = (webStyle: any, selectTheme: any, MenuProps: any) => {
    switch (this.state.mode) {
      case "edit":
        return <>{this.renderEditUi(webStyle, selectTheme, MenuProps)}</>
      case "create":
        return <>{this.renderCreateSubscriptionUi(webStyle, selectTheme, MenuProps)}</>

      default:
        return null;
    }
  }

  restoreDialogSubmit = () => {
    this.handleRestoreData()
    this.handleArchivedSubscriptionApi()
  }

  renderDialogRestore = (webStyle: any) => {
    return (
      <Dialog open={this.state.restoreSubscriptionDialog} aria-labelledby="form-dialog-title"> {this.renderLoader()}
        <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
          <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: 'clamp(18px, 32px, 5vw)' }} /> Restore Subscription </Typography>
        <DialogContent><DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}> Are you sure you want to restore this subscription plan? The plan will be available to all to subscriber. </DialogContentText> </DialogContent>
        <DialogActions style={{ justifyContent: 'center', gap: 'clamp(10px, 24px, 3%)', flexWrap: 'wrap' }}>
          <CustomeButton text="Close" onclicked={this.handleRestoreDialogClose} primary={false} style={{ ...ButtonCloseRestore }} data-test-id="archive-dialog-close-btn" />

          <CustomeButton text="Restore" onclicked={this.restoreDialogSubmit} primary={true} style={{ ...webStyle.dialogButtonStyle, border: '1px solid #F4CD46', }} data-test-id="archive-dialog-archive-btn" />
        </DialogActions>
      </Dialog>
    )
  }

  afterLogInDataResponse = (logInRes: any) => {
    if (logInRes) {
      this.setState({
        logInToken: logInRes.logInToken
      })
    }
  }

  stateSetAfterSignup = (otpConfRes: any) => {
    if (otpConfRes) {
      this.setState({ logInToken: otpConfRes.token });
    }
  }

  setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

    if (!logInRes && !otpConfRes && !token) {
      window.localStorage.clear();
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
    }

    if (token) {
      const logRes = { logInToken: token };

      setStorageData("logInRes", JSON.stringify(logRes));
      this.setState({ logInToken: token });
      removeStorageData("isProfileCreated");
      setStorageData("userLoggedIn", true);
      setStorageData("isLogin", true);
    } else {
      this.stateSetAfterSignup(otpConfRes);
      this.afterLogInDataResponse(logInRes);
    }

  }

  getBranchNameList = (branch: any) => {
    let branchArray;
    if (branch) {
      try {
        branchArray = JSON.parse(branch);
        return branchArray.join(",");
      } catch (error) {
        console.error("Invalid JSON format in branch:", branch);
        return "";
      }
    }
    return "";
  }

  // Customizable Area End
}
